import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Card,
  CircularProgress,
  debounce,
  Grid,
  InputAdornment,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Main from "../../_core/components/layout/Main";
import UIButton from "../../_core/components/ui/UIButton";
import UITextInput from "../../_core/components/ui/UITextInput";
import UIBack from "../../_core/components/ui/UIBack";
import { useAssignUser, useCreateUser, useExistingUser } from "../hooks";
import { useApiAlerts, useValidation } from "../../_core/hooks";
import UIAlert from "../../_core/components/ui/UIAlert";
import { useQueryClient } from "react-query";

interface LocationState {
  accountId: string;
  accountCode: string;
}

export const AddUserContainer = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const accountId = location?.state?.accountId;
  const accountCode = location?.state?.accountCode;

  const {
    mutate: createUser,
    isLoading: isCreating,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
    error: createError,
  } = useCreateUser();
  useApiAlerts(
    isCreateSuccess ? "User created" : undefined,
    createError,
    isCreateError
  );

  const {
    mutate: assignUser,
    isLoading: isAssigning,
    isSuccess: isAssignSuccess,
    isError: isAssignError,
    error: assignError,
  } = useAssignUser();
  useApiAlerts(
    isAssignSuccess ? "User assigned" : undefined,
    assignError,
    isAssignError
  );

  const [fields, setFields] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
  });
  const [debouncedEmail, setDebouncedEmail] = useState(fields.email);
  const debounceEmailUpdate = useCallback(
    debounce((email: string) => setDebouncedEmail(email), 400),
    []
  );

  const {
    validateAll,
    validateSingle,
    feedbackProps,
    validationErrors,
    resetValidation,
  } = useValidation(
    {
      email: { required: true, isEmail: true },
      firstName: { required: true },
      lastName: { required: true },
      contactNumber: { required: false },
    },
    fields
  );
  const hasError = Object.values(feedbackProps).some(
    (value: any) => value.error
  );

  const { data: existingUser, isLoading: isLoadingExistingUser } =
    useExistingUser(debouncedEmail, {
      enabled: !!debouncedEmail && !validationErrors.email,
    });

  const previousHasExistingUser = useRef(false);
  const hasExistingUser = existingUser?.email === fields.email;
  const canSubmit = Boolean(accountId);
  const isSubmitting = isCreating || isAssigning;
  const disableInput = !canSubmit || hasExistingUser || isSubmitting;
  const disableSubmit =
    !canSubmit || hasError || isLoadingExistingUser || isSubmitting;

  useEffect(() => {
    // Clears pre-populated fields only if these were pre-populated previously for an existing user
    if (previousHasExistingUser && !hasExistingUser) {
      setFields((f) => ({
        ...f,
        firstName: "",
        lastName: "",
        contactNumber: "",
      }));
      previousHasExistingUser.current = hasExistingUser;
    }
  }, [hasExistingUser]);

  const handleInput = (ev: ChangeEvent<HTMLInputElement>) => {
    const key = ev.target.name;
    const val = ev.target.value;
    setFields((f) => ({ ...f, [key]: val }));
    validateSingle(key, val);
    if (key === "email") debounceEmailUpdate(val);
  };

  const handleSubmit = () => {
    const errors = validateAll();
    if (!errors.length) {
      const params = { accountId, ...fields };
      if (hasExistingUser) {
        assignUser({ accountId, userId: existingUser.id });
      } else {
        createUser(params);
      }
    }
  };

  useEffect(() => {
    if (isCreateSuccess || isAssignSuccess) history.goBack();
  }, [isCreateSuccess, isAssignSuccess, history]);

  const isDifferentUser = () => {
    if (!existingUser) return true;
    return (
      fields.email !== existingUser.email ||
      fields.firstName !== existingUser.givenName ||
      fields.lastName !== existingUser.familyName ||
      (existingUser.phoneNumber &&
        fields.contactNumber !== existingUser.phoneNumber)
    );
  };

  useEffect(() => {
    if (hasExistingUser && isDifferentUser()) {
      resetValidation();
      setFields({
        email: existingUser.email,
        firstName: existingUser.givenName,
        lastName: existingUser.familyName,
        contactNumber: existingUser.phoneNumber ?? "",
      });
    }
  }, [fields, existingUser, hasExistingUser, isDifferentUser]);

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(["existingUser"]);
    };
  }, []);

  return (
    <Main
      data-testid="new-admin-user-container"
      title="Add New or Existing User"
    >
      <div className={classes.root}>
        <Grid container item>
          <UIAlert severity="info" data-testid="info-alert">
            Create a new admin user or assign an existing one on the '
            {accountCode}' account. The user will gain access to all features
            enabled on the account.
          </UIAlert>
        </Grid>
        <Grid container item justifyContent="space-between">
          <UIBack />
        </Grid>
        <Grid container item>
          <Grid item md={6}>
            <Card className={classes.card} data-testid="new-admin-user-card">
              {!accountId && <UIAlert>Account not selected</UIAlert>}
              <UITextInput
                name="email"
                placeholder="Email"
                value={fields.email}
                onChange={handleInput}
                variant="outlined"
                size="small"
                fullWidth
                disabled={!canSubmit || isSubmitting}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isLoadingExistingUser ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null}
                    </InputAdornment>
                  ),
                }}
                {...feedbackProps.email}
              />
              <UITextInput
                name="firstName"
                placeholder="First Name"
                value={fields.firstName}
                onChange={handleInput}
                variant="outlined"
                size="small"
                fullWidth
                disabled={disableInput}
                {...feedbackProps.firstName}
              />
              <UITextInput
                name="lastName"
                placeholder="Last Name"
                value={fields.lastName}
                onChange={handleInput}
                variant="outlined"
                size="small"
                fullWidth
                disabled={disableInput}
                {...feedbackProps.lastName}
              />
              <UITextInput
                name="contactNumber"
                placeholder="Contact Number"
                value={fields.contactNumber}
                onChange={handleInput}
                variant="outlined"
                size="small"
                fullWidth
                disabled={disableInput}
                {...feedbackProps.contactNumber}
              />
              <UIButton
                data-testid="new-admin-user-submit"
                size="medium"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                disabled={disableSubmit}
                fullWidth
              >
                {hasExistingUser ? "Assign User" : "Add New User"}
              </UIButton>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Main>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
}));

export default AddUserContainer;
