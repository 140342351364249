import { format, parseISO } from "date-fns";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isEqual(val1: any, val2: any) {
  return val1 === val2;
}

export function isEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isEmpty(val: any) {
  if (typeof val === "number") return false;
  if (Array.isArray(val)) return !val.length;
  return !val;
}

export function isDate(val: any) {
  return Boolean(Date.parse(val));
}

export function uniq(a: any[]) {
  return Array.from(new Set(a));
}

export function flatten(arr: any = [], nestedKey?: string) {
  const func = (a: any, b: any) => {
    let target;
    if (Array.isArray(b)) target = b;
    else if (nestedKey && Array.isArray(b[nestedKey])) target = b[nestedKey];
    return a.concat(target ? flatten(target, nestedKey) : b);
  };
  return arr.reduce(func, []);
}

export function percentage(percent: number, total: number) {
  return (percent / 100) * total;
}

export function randomFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function bufferToObject(buffer: any) {
  return JSON.parse(Buffer.from(buffer).toString("utf8"));
}

export const findMatchingById = (id: string, list: any[]) => {
  return list.find((item) => item.id === id);
};

export function errorRespParse(resp: any) {
  if (resp.request.responseType === "arraybuffer") {
    return bufferToObject(resp.data);
  }
  return resp;
}

export function extractFilenameFromHeaders(response: any) {
  const regex = /filename="(.*?)"/g;
  const key = response.headers["content-disposition"];
  const filename = regex.exec(key)?.[1];
  return filename;
}

export function downloadFile(data: any, filename: string, filetype?: string) {
  const link = document.createElement("a");
  const file = filetype ? `${filename}.${filetype}` : filename;
  link.href = window.URL.createObjectURL(new Blob([data]));
  link.setAttribute("download", file);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function loadScript(src: string, id?: string) {
  const tag = document.createElement("script");
  tag.async = false;
  tag.src = src;
  if (id) tag.id = id;
  document.body.appendChild(tag);
}

export function groupBy(array: any[], key: any) {
  const output = array.reduce(function (r: any, a: any) {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create(null));
  return output;
}

export function isArray(a: any) {
  return !!a && a.constructor === Array;
}

export function isObject(a: any) {
  return !!a && a.constructor === Object;
}

export const formatDate = (date: string | undefined | null) => {
  if (date) return formatDateWithPattern(date, "dd/MM/yyyy");
  return "";
};

export const formatDateWithPattern = (
  date: string | undefined,
  pattern: string
) => {
  return date ? format(parseISO(date), pattern) : "";
};

export const humanize = (str?: string) => {
  if (!str) return str;
  let i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] =
      frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
  }
  return frags.join(" ");
};
